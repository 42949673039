$(document).on('click', 'a[data-toggle="modal"]', function(e) {
  var modal = $($(this).attr('data-target'));
  e.preventDefault();
  // Empty form
  if($(this).attr('data-clear-form')){
    console.log('cclear');
    modal.find("input[type='text'], textarea").val('')
  }
  modal.fadeIn(250);
});

$(document).on('click', '*[data-dismiss="modal"]', function(e) {
  e.preventDefault();
  $(this).parents('.modal').fadeOut(250);
});

$(document).keyup(function(e) {
     if (e.keyCode == 27) { // escape key maps to keycode `27`
       $(".modal").fadeOut(250);
    }
});
