var EasyMDE = require("easymde");

if ( $('#main-form .md-editor').length ){
  // Loop editors
  $('#main-form .md-editor').each(function() {

    var editor = new EasyMDE({

    	element: this,
      toolbar: [
        "unordered-list", "link", "|", "preview", "side-by-side", "fullscreen", "|",
        {
          name: "help",
          action: function customFunction(editor){
            window.open('http://variable.club/_docs/markdown-guide.html', '_blank');
          },
          className: "fa fa-question-circle",
          title: "Help",
        }
      ],
      uploadImage:true,
      imageUploadFunction: function(file, onSuccess, onError) {

        var form_data = new FormData();
        var url;
        var imageUrl;
        var message;
        form_data.append('file', file);
        form_data.append('_token', $('meta[name="csrf-token"]').attr('content'));
        $.ajax({
              url:admin_url + '/fileupload',
              data: form_data,
              dataType:'json',
              type:'POST',
              processData: false,
              contentType: false,
              success:function(response){
                imageUrl = response.image_url;
              },
              error: (error) => {
                message = error.responseJSON.message;
              }
          })
          .then((url) => onSuccess(imageUrl))
          .catch((error) => onError(message));
	    },
      // imageUploadEndpoint: admin_url + "/markdowneditorfileupload",
      // imageCSRFToken: $('meta[name="csrf-token"]').attr('content'),
      hideIcons: ['image'],
      showIcons: ['upload-image'],
      spellChecker: false,
    });
  });
}
