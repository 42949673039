$(document).ready(function() {

  // ----- Hide index alerts ----- //

  window.setTimeout(function(){
    $(".alert").fadeOut(600);
  }, 2000);

  window.setTimeout(function(){
      $(".help-block").fadeOut(600);
  }, 5000);

  $("body").on({
      mouseenter: function() {
        $('.help-block').fadeOut(300);
      }
  }, ".help-block");

  $("body").on({
      mouseenter: function() {
        $('.alert').fadeOut(300);
      }
  }, ".alert");

});
