let
  preview = document.querySelector('#preview'),
  openPreview = document.querySelector('#btn-preview-open'),
  close = document.querySelector('#btn-preview-close'),
  iframe = document.querySelector('#preview-iframe'),
  iframeContainer = document.querySelector('#preview-iframe-container'),
  toolsItem = document.querySelectorAll('.tools__item'),
  sizeS = document.querySelector('#preview-size-s'),
  sizeM = document.querySelector('#preview-size-m'),
  sizeL = document.querySelector('#preview-size-l');


// OPEN / CLOSE
if(openPreview){
  openPreview.addEventListener('click', function (e) {
    document.body.style.overflow = 'hidden';
    preview.classList.add('active');
  }, false);

  close.addEventListener('click', function () {
    document.body.style.overflow = '';
    preview.classList.remove('active');
  }, false);
}

// SIZE BUTTONS

function switchSize(el, width, height) {
  for (let i of toolsItem)
    if (i.classList.contains('active')) i.classList.remove('active');
  console.log(this);
  el.classList.add('active');
  iframe.style.width = width;
  iframe.style.height = height;
}

sizeS.addEventListener('click', function () {
  switchSize(sizeS, '375px', '100%')
});
sizeM.addEventListener('click', function () {
  switchSize(sizeM, '768px', '100%')
});
sizeL.addEventListener('click', function () {
  switchSize(sizeL, '100%', '100%')
});
