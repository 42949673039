var select2 = require("select2");
select2($);

$(document).ready(function() {
  // ----- Dropdown selects ----- //
  if ( $('.select2').length ){
    $('.select2').select2({
      placeholder : 'Select or add something',
      tags        : true,
      tokenSeparators: [","],
      allowClear: true,
    });
  }
});
