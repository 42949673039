var select2 = require("select2");
select2($);

$(document).ready(function() {

  ////
  // ----- Medias panel display data----- //
  ////

  if( $('.relationship-panel').length ){
    $('.relationship-panel').each(function( index ) {
      var related_model = $(this).attr('data-related_model');
      // Build select2 list
      getRelations(related_model);
    });
  }


  ////
  // ----- Submit form if select2 select change ----- //
  ////

  $('body').delegate('.select-relationships','change', function(){
    var form = $(this).closest('form');
    form.ajaxForm({dataType: 'json'}).submit();
  });


  ////
  // ----- Modal launch / Create related element ----- //
  ////

  $('.modal--relationship').on('click', '.relation-submit', function(e) {
    e.preventDefault();
    var form = $(this).closest('form');
    var options = {
      dataType: 'json',
      error: function (xhr, status, error, $form) {
        $form.find('.alert').show().fadeOut(2500);
        return false;
      },
      success: showResponse,
      clearForm:true,
      async: false, // preventing other events on the page from firing
    };
    form.ajaxForm(options).submit();
  });

}) // End on document ready


////
// ----- Get medias list based on colection and print html list ----- //
////

function getRelations(related_model) {
  var panel = $("#panel-" + related_model);
  parent_id = panel.attr('data-parent_id');
  var jsonUrl = admin_url + '/relationships/get/' + table_type + '/' + article_id + '/' + related_model+ '/' + parent_id;
  var result;
  // Get from Json
  $.ajax({
      dataType: 'JSON',
      url: jsonUrl,
      async: false, // preventing other events on the page from firing
  }).done(function(data){
    result = data;
    populateDropdown(related_model, result);
  });
  return result;
}


////
// ----- Print the media ----- //
////

function populateDropdown(related_model, data) {
  var dropdown = $('#select-' + related_model);
  // Reset dropdown
  dropdown.empty();
  // Populate dropdown
  dropdown.select2({
    placeholder : 'Select something',
    tokenSeparators: [','],
    allowClear: true,
    data: data
  });
}


////
// ----- Form submit success ----- //
////

function showResponse(responseText, statusText, xhr, $form)  {
  // Hide modal
  $form.parents('.modal').fadeOut(250);
  // Refresh dropdown
  getRelations(responseText.related_model);
  // Hide error message
  $form.find('.error').hide();
  // Reset fields
  $form.find("input[type='text'], textarea").val('');
}
